  <template>
  <div>
    <b-field :label="label" v-if="!adaptedReqErr">
      <b-input
        type="textarea"
        v-model="adaptedRequest"
        :disabled="isLoading"
        :loading="isLoading"
      ></b-input>
    </b-field>
    <section class="section" v-else>
      <div class="content has-text-grey has-text-centered">
        <p>
          <b-icon icon="emoticon-sad" size="is-large"></b-icon>
        </p>
        <p>{{ adaptedReqErr }}</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AdaptedRequest',
  props: {
    cleanPaymentData: Object,
  },
  computed: {
    label() {
      return `Request sent to the payment provider (${this.cleanPaymentData.merchant.paymentProvider}):`;
    },
  },
  created() {
    this.$dataService(this.tenant)
      .getAdaptedRequest(this.paymentPlanId)
      .then((result) => {
        this.adaptedRequest = JSON.stringify(
          result.data.fields || result.data.url,
        );
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          this.adaptedReqErr = 'Request details aren\'t currently available for this payment flow';
        } else {
          this.adaptedReqErr = `Error to get sent request. Error: ${err}`;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  data() {
    const { paymentPlanId, tenant } = this.$route.params;
    return {
      paymentPlanId,
      tenant,
      adaptedRequest: null,
      isLoading: true,
      adaptedReqErr: null,
    };
  },
};
</script>

  <style lang="scss" scoped>
</style>
